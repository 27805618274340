import React from 'react';
import styled from 'styled-components';
import errorState from './error-state.svg';
import constants from 'lib/constants';
import logo from 'lib/media/logo.svg';
import theme from 'lib/theme';

const Container = styled.div`
  height: 90%;
  min-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
`;

const Content = styled.div``;

const Title = styled.h1`
  color: ${(p) => p.theme.colors.neutral2};
  font-size: 24px;
  margin: 0px;
  margin-top: 16px;
`;

const Subtitle = styled.h3`
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 18px;
  margin: 0px;
  margin-top: 8px;
  font-weight: 400 !important;
  width: 450px;
  max-width: 100%;
`;

const Header = () => {
  return (
    <div
      style={{
        height: 56,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.colors.neutral9}`,
      }}
    >
      <img src={logo} height="32" alt="logo" />
    </div>
  );
};

export default ({
  title = 'An error occured...',
  subtitle = `Please try to refresh. If the error persists, contact support at
  ${constants.supportEmail}`,
}) => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <img
            src={errorState}
            alt="error-state"
            height="250"
            style={{opacity: 0.5}}
          />
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Content>
      </Container>
    </>
  );
};
