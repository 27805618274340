import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import authLink from './links/authLink';
import { createUploadLink } from 'apollo-upload-client';
import apolloLogger from 'apollo-link-logger';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_HOST || 'http://localhost:8080/graphql',
  headers: {
    'keep-alive': 'true',
  },
});

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [authLink, apolloLogger, uploadLink];

if (process.env.NODE_ENV === "production") {
  links = [authLink, uploadLink];
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
});

export default client;
