import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
// COMPONENTS
import Popover from 'components/common/Popover';
import Avatar from 'components/common/Avatar';
import {
  MenuItemBtn,
  SectionTitle,
  SectionBreak,
} from 'components/common/AvatarMenu';
// LIB
import AuthHelpers from 'lib/helpers/AuthHelpers';
import ErrorHelpers from 'lib/helpers/ErrorHelpers';
import logo from 'lib/media/logo.svg';
import theme from 'lib/theme';

// STYLE-COMPONENTS
// ===================================
const Layout = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => p.theme.colors.neutral10};
`;

const Header = styled.div`
  height: 56px;
  background: #fff;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const HeaderInner = styled.div`
  height: 100%;
  width: 1200px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: auto;
`;

const Content = styled.div`
  height: 100%;
  padding-top: 24px;
  width: 1200px;
  max-width: 100%;
  margin: auto;
`;

const HeaderBlock = ({history, currentUser}) => {
  const onLogout = async () => {
    try {
      // run logout function
      await AuthHelpers.signOut();
    } catch (err) {
      return ErrorHelpers.handleError(err);
    }
  };
  return (
    <Header>
      <HeaderInner>
        {' '}
        <Link to={`/buyer/home`}>
          <img src={logo} height="32" alt="logo" />
        </Link>
        <div style={{position: 'absolute', right: 0}}>
          <Popover
            overlayStyle={{padding: 0, borderRadius: 5}}
            content={
              <div style={{width: 200}}>
                <SectionTitle>ACCOUNT</SectionTitle>
                <MenuItemBtn onClick={() => history.push(`/orders`)}>
                  My Orders
                </MenuItemBtn>
                <MenuItemBtn
                  onClick={() => history.push(`/buyer/settings?tab=account`)}
                >
                  Account Details
                </MenuItemBtn>
                <MenuItemBtn
                  onClick={() => history.push(`/buyer/settings?tab=user`)}
                >
                  Profile
                </MenuItemBtn>
                <MenuItemBtn
                  onClick={() => history.push(`/buyer/settings?tab=payment`)}
                >
                  Payment & Billing
                </MenuItemBtn>
                <MenuItemBtn
                  onClick={() => history.push(`/buyer/settings?tab=security`)}
                >
                  Security
                </MenuItemBtn>
                <MenuItemBtn
                  onClick={() =>
                    history.push(`/buyer/settings?tab=notifications`)
                  }
                >
                  Notifications
                </MenuItemBtn>
                <SectionBreak />
                <SectionTitle>Support</SectionTitle>
                <MenuItemBtn onClick={() => history.push('/support')}>
                  Contact Us
                </MenuItemBtn>

                <SectionBreak />
                <MenuItemBtn
                  onClick={() => onLogout()}
                  style={{color: theme.colors.red3, marginBottom: 0}}
                >
                  Logout
                </MenuItemBtn>
              </div>
            }
          >
            <Avatar
              style={{
                cursor: 'pointer',
              }}
              size="large"
            >
              {currentUser.firstName && currentUser.firstName.charAt(0)}
              {currentUser.lastName && currentUser.lastName.charAt(0)}
            </Avatar>
          </Popover>
        </div>
      </HeaderInner>
    </Header>
  );
};

// EXPORT
// ===================================
export default function BuyerLayout({children, header, history, currentUser}) {
  return (
    <Layout id="buyer-layout">
      {header ? (
        header
      ) : (
        <HeaderBlock history={history} currentUser={currentUser} />
      )}
      <Content>{children}</Content>
    </Layout>
  );
}
