import numeral from 'numeral';
import storage from 'local-storage-fallback';

export const ORDER_STATUSES = [
  {
    value: 'pendingPayment',
    label: 'Pending Payment',
    description:
      'The order is created but we are still waiting for confirmation of payment (e.g. pay by check of ACH Credit)',
  },
  {
    value: 'pending',
    label: 'Pending',
    description:
      'Customer has completed the checkout process and payment has been confirmed. We are waiting to see if the distributor actually has the products in stock',
  },
  {
    value: 'readyForPickup',
    label: 'Ready for Pickup',
    description:
      'The shipment is ready for pickup at a shipping depot and shipping labels have been provided to relevant parties',
  },
  {
    value: 'shipped',
    label: 'Shipped',
    description:
      'The shipment is being transported between shipping facilities on the way to its destination.',
  },
  {
    value: 'delivered',
    label: 'Delivered',
    description: 'The shipment has reached its destination.',
  },
  {
    value: 'completed',
    label: 'Completed',
    description:
      'Buyer has confirmed the received the shipment and everything looks good OR 30 days elapses',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    description:
      'Seller has cancelled an order, due to a stock inconsistency or other reasons.',
  },
  {
    value: 'disputed',
    label: 'Disputed',
    description: `Customer has initiated a dispute resolution process for the transaction that paid for the order. "order never arrived" "order damanged" etc all make an order move to "disputed"`,
  },
  {
    value: 'refunded',
    label: 'Refunded',
    description: `Seller has refunded the order.`,
  },
  {
    value: 'partiallyRefunded',
    label: 'Partially Refunded',
    description: `Seller has partially refunded the order.`,
  },
];

const iOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const helpers = {
  getLocalStorage: (valueName) => {
    try {
      if (!iOS()) {
        return window.localStorage.getItem(valueName);
      } else {
        return storage.getItem(valueName);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  },
  setLocalStorage: (valueName, valueToSet) => {
    try {
      if (!iOS()) {
        return window.localStorage.setItem(valueName, valueToSet);
      } else {
        return storage.setItem(valueName, valueToSet);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  },
  removeLocalStorage: (valueName) => {
    try {
      if (!iOS()) {
        return window.localStorage.removeItem(valueName);
      } else {
        return storage.removeItem(valueName);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  },
  getWeightFromLineItems: (lineItems) => {
    if (lineItems && lineItems.length === 0) return 0;
    return lineItems
      .map((item) => parseFloat(item.weight * item.quantity))
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  },
  /**
   * Given a string, this will captilize the first letter of the string.
   */
  capitalize: (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  /**
   * Given a user ID, this will identify a user in mixpanel analyltics and fullstory
   */
  identifyUser: (userId) => {
    if (!userId) {
      throw new Error('userId is required to identify a user in mixpanel');
    }
    if (window.FS) {
      window.FS.identify(userId);
    }
  },
  centsToDollars: (value) => {
    return (value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },
  dollarsToCents: (amount) => {
    if (typeof amount !== 'string' && typeof amount !== 'number') {
      throw new Error('Amount passed must be of type String or Number.');
    }
    return Math.round(
      100 *
        parseFloat(
          typeof amount === 'string' ? amount.replace(/[$,]/g, '') : amount
        )
    );
  },
  cleanTypenameFromArray: (arrayValue) => {
    if (!arrayValue || arrayValue.length === 0) return null;
    return arrayValue.map((value) => {
      let newObj = {
        ...value,
      };
      if (newObj && newObj.__typename) {
        delete newObj.__typename;
      }
      return newObj;
    });
  },
  iOS,
  formatWatts: (watts) => {
    return numeral(watts).format('0.0a');
  },
  getSubtotal: (products) => {
    if (!products || products.length === 0) return 0;
    return products
      .map((item) => item.price * (item.quantity || 1))
      .reduce((a, b) => a + b, 0);
  },
  orderStatusToLabel: (value) => {
    return ORDER_STATUSES.filter((stat) => stat.value === value)[0].label;
  },
  getDollarsPerWatt: (dollars, watts) => {
    if (!dollars || dollars === 0) return 0;
    return `$ ${(watts / dollars).toFixed(2)}`;
  },
  getFullName: (user) => {
    if (!user) return null;
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName && !user.lastName) {
      return user.firstName;
    }
    if (!user.firstName && user.lastName) {
      return user.lastName;
    }
  },
};

export default helpers;
